import { faUser } from "@fortawesome/free-regular-svg-icons";
import {
  faEllipsisV,
  faMapMarkerAlt,
  faShoppingCart,
  faSignInAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { cross1, locator, menu } from "../../constants/imagesContains";
import { connect } from "react-redux";
import {
  setShowLoggedInPopup,
  setShowRegisterPopup,
  setUserLocation,
} from "../../_redux/actions";
import { Dropdown, Form, Nav, Navbar } from "react-bootstrap";
import Link from "next/link";
import dynamic from "next/dynamic";
import Image from "next/image";
import { useRouter } from "next/router";
import Autocomplete from "react-google-autocomplete";

const ConfirmLogoutModal = dynamic(import("../modals/ConfirmLogoutModal"));
const googleapikey = "AIzaSyBTI8D4170T574-kuFjTOg_zDJDLA7e80k";
const mapStateToProps = (state) => ({
  isLoggedIn: state.user.isLoggedIn,
  userLocation: state.user.userLocation,
  serviceSegments: state.user.serviceSegments,
  siteData: state.user.siteData,
});

const mapDispatchToProps = (dispatch) => ({
  setShowLoggedInPopup: (data) => {
    dispatch(setShowLoggedInPopup(data));
  },
  setShowRegisterPopup: (data) => {
    dispatch(setShowRegisterPopup(data));
  },
  setUserLocation: (data) => {
    dispatch(setUserLocation(data));
  },
});
const geocodeJson = "https://maps.googleapis.com/maps/api/geocode/json";

function Header(props) {
  const {
    userLocation,
    setUserLocation,
    setShowRegisterPopup,
    setShowLoggedInPopup,
    isLoggedIn,
    siteData,
    serviceSegments,
  } = props;

  const [showLocationbar, setShowLocationbar] = useState(false);
  const [currentLocation, setCurrentLocation] = useState("");
  const [showMenuBar, setShowMenuBar] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const searchInput = useRef(null);
  const router = useRouter();

  const handleShowMenu = () => setShowMenu(!showMenu);

  function logout() {
    setShowLogoutModal(true);
  }

  useEffect(() => {
    if (localStorage.getItem("currentLatLong")) {
      const currentLatLong = JSON.parse(localStorage.getItem("currentLatLong"));
      setCurrentLocation(currentLatLong);
      setUserLocation(currentLatLong);
    }
  }, []);

  //get address using geocode api
  const reverseGeocode = ({ latitude: lat, longitude: lng }) => {
    const url = `${geocodeJson}?key=${googleapikey}&latlng=${lat},${lng}`;
    fetch(url)
      .then((response) => response.json())
      .then((location) => {
        console.log("p", location);
        const place = location.results[0];
        if (place) {
          // const place = location.results[0];

          const address = place.address_components.filter((item) => {
            if (
              item.types[0] ===
              ("administrative_area_level_2" || "administrative_area_level_1")
            ) {
              return item.long_name;
            }
          });

          const city = address?.[0]?.["long_name"];
          const fullAddress = place.formatted_address;
          searchInput.current.value = city;
          localStorage.setItem(
            "currentLatLong",
            JSON.stringify({ lat, lng, fullAddress, city })
          );
          setCurrentLocation({ lat, lng, fullAddress, city });
          setShowLocationbar(false);
        }
      });
  };

  //get current location latitude and longitude
  const findMyLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        reverseGeocode(position.coords);
      });
    }
  };

  // auto complete location input
  function autoCompleteAddress() {
    const autocomplete = new google.maps.places.Autocomplete(
      searchInput.current
    );
    autocomplete.setTypes(["(cities)"]);
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      const address = place.address_components.filter((item) => {
        if (
          item.types[0] ===
          ("administrative_area_level_2" || "administrative_area_level_1")
        ) {
          return item.long_name;
        }
      });
      const city = address?.[0]?.["long_name"];
      // const city = place.vicinity;
      const fullAddress = place.formatted_address;
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();

      localStorage.setItem(
        "currentLatLong",
        JSON.stringify({ lat, lng, fullAddress, city })
      );
      setCurrentLocation({ lat, lng, fullAddress, city });
      setShowLocationbar(false);
    });
  }

  useEffect(() => {
    if (currentLocation.fullAddress) {
      setUserLocation(currentLocation);
    }
  }, [currentLocation.fullAddress]);

  return (
    <>
      <div className="container nav-container">
        {
          <ConfirmLogoutModal
            show={showLogoutModal}
            closeModal={() => setShowLogoutModal(false)}
          />
        }
        <Navbar
          collapseOnSelect
          expand="lg"
          bg="white"
          variant="dark"
          className="py-0 align-items-center apporio-navbar"
        >
          <Navbar.Brand className="text-dark navbar-inner ps-lg-0 ps-2 ">
            {Object.keys(siteData).length > 0 && (
              // <Link>
              <div className="ms-lg-5 py-0">
                <img
                  src={siteData?.header?.logo}
                  alt=""
                  title={siteData?.header?.title}
                  height={60}
                  className="cursor-pointer"
                  onClick={() => router.pathname !== "/" && router.push("/")}
                />
              </div>
              // </Link>
            )}
          </Navbar.Brand>
          {/* collapse Menu */}
          <div
            className={`mobile-menu-bar py-3 h-max-content shadow ${
              showMenu ? "mobile-menu-bar-collapse " : ""
            }`}
          >
            <div className="px-3">
              <div className="row text-uppercase fs-10 fw-500 label-color-2 font-sfpro">
                {serviceSegments?.map((item, index) => (
                  <div
                    key={index}
                    className="col-6 d-flex align-items-center pb-2 text-nowrap"
                  >
                    <Link href={`/service/${item.title}`}>
                      <a
                        className="text-decoration-none fs-13 label-color-2"
                        onClick={() => setShowMenu(false)}
                      >
                        <img
                          src={item.image}
                          className="me-md-3 me-2"
                          width={20}
                          height={20}
                          alt=""
                        />
                        {item.name}
                      </a>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/*  */}
          <div className="w-100 mobile-menu-navbar">
            <div className="w-max-content py-2 ps-2 d-flex align-items-center">
              {serviceSegments.length > 0 && (
                <div className="pe-2 menu-icon-box text-start">
                  {showMenu ? (
                    <img
                      src={cross1}
                      onClick={handleShowMenu}
                      className="ms-auto"
                      width={20}
                      alt=""
                    />
                  ) : (
                    <img
                      src={menu}
                      alt="Menu"
                      title="Menu"
                      width={30}
                      className="cursor-pointer"
                      onClick={handleShowMenu}
                    />
                  )}
                </div>
              )}
              <Link href="/">
                <a className="py-0">
                  <img
                    src={siteData?.header?.logo}
                    width={50}
                    height={40}
                    alt=""
                  />
                </a>
              </Link>
            </div>
            <div className="col d-flex align-items-center justify-content-end text-end">
              {/* location */}
              <div className="pe-3">
                <div
                  className="nav-item fs-15 fw-600 text-nowarp active-link cursor-pointer text-decoration-none text-dark"
                  onClick={() => setShowLocationbar(!showLocationbar)}
                >
                  <FontAwesomeIcon
                    className="me-1 fs-22"
                    icon={faMapMarkerAlt}
                    width={18}
                    height={18}
                  />{" "}
                  {userLocation?.city || ""}
                </div>
              </div>
              {/* cart */}
              {/* <div className='pe-3'>
                <FontAwesomeIcon icon={faShoppingCart} className='fs-20' />
              </div> */}
              <div className="pe-3 mobile-user-menu">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="border-0 py-0 bg-transparent"
                  >
                    <FontAwesomeIcon
                      icon={faEllipsisV}
                      className="text-dark fs-20"
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {(!isLoggedIn && (
                      <>
                        <Dropdown.Item
                          onClick={() => setShowLoggedInPopup(true)}
                        >
                          Login
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => setShowRegisterPopup(true)}
                        >
                          Sign Up
                        </Dropdown.Item>
                      </>
                    )) || (
                      <>
                        <Dropdown.Item>
                          <Link href="/my-orders">
                            <a className="text-decoration-none text-black">
                              {" "}
                              My Orders
                            </a>
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                      </>
                    )}
                    {/* <Dropdown.Item>Terms of Use</Dropdown.Item>
                    <Dropdown.Item>Privacy Pollicy</Dropdown.Item>  */}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          <Navbar className="navbar-inner w-100" id="responsive-navbar-nav">
            <Nav className="me-auto">
              {/*  */}
              <Nav.Link className="">
                {serviceSegments.length > 0 && (
                  <div className="ms-3">
                    {showMenuBar ? (
                      <img
                        src={cross1}
                        alt="Menu"
                        title="Menu"
                        width={30}
                        className="cursor-pointer"
                        onClick={() => setShowMenuBar(false)}
                      />
                    ) : (
                      <img
                        src={menu}
                        alt="Menu"
                        width={60}
                        title="Menu"
                        className="cursor-pointer"
                        onClick={() => setShowMenuBar(true)}
                      />
                    )}
                  </div>
                )}
              </Nav.Link>
            </Nav>
            <ul className="d-flex align-items-center list-unstyled m-0 ">
              <li className="nav-link">
                <div
                  className="nav-item fs-23 fw-600 active-link cursor-pointer text-decoration-none"
                  onClick={() => setShowLocationbar(!showLocationbar)}
                >
                  <FontAwesomeIcon
                    className="me-2"
                    icon={faMapMarkerAlt}
                    width={18}
                    height={18}
                  />{" "}
                  {userLocation?.city || "Choose Location"}
                </div>
              </li>
              {isLoggedIn && (
                <li className="nav-link">
                  <Link href="/my-orders">
                    <a className="nav-item text-decoration-none text-black fs-23 fw-600">
                      My Orders
                    </a>
                  </Link>
                </li>
              )}
              {isLoggedIn && (
                <li className="nav-link">
                  <div className="nav-item">
                    <button
                      className="fs-23 text-white fw-500 ms-3 px-3 pb-1 me-0 login-sign-up btn-login rounded"
                      title="logout"
                      onClick={logout}
                    >
                      <FontAwesomeIcon
                        icon={faSignInAlt}
                        width={18}
                        height={18}
                        className="fs-18"
                      />{" "}
                      Logout
                    </button>
                  </div>
                </li>
              )}
              {/* {isLoggedIn && (
                <Nav.Link className="user-menu">
                  <div className="nav-item dropdown pe-lg-5 cursor-pointer">
                    <span className="text-decoration-none text-dark  fs-23 fw-600">
                      <FontAwesomeIcon
                        icon={faUser}
                        className="me-2"
                        width={18}
                        height={18}
                      />{" "}
                      My Account
                    </span>
                    <div
                      className="dropdown-menu px-0 py-2 rounded"
                      aria-labelledby="navbarDropdown"
                    >
                      <span
                        className="dropdown-item bg-white px-5"
                        title="My Order"
                        onClick={() => router.push("/my-orders")}
                      >
                        My Order
                      </span>

                      <span
                        className="dropdown-item bg-white px-5"
                        title="Logout"
                        onClick={logout}
                      >
                        Logout
                      </span>
                    </div>
                  </div>
                </Nav.Link>
              )} */}
              {!isLoggedIn && (
                <li className="nav-link" eventKey={2}>
                  <div className="d-flex">
                    <div className="nav-item d-flex align-items-center">
                      <button
                        className="fs-23 text-white fw-500 px-3 pb-1 login-sign-up rounded"
                        title="Sign Up"
                        onClick={() => setShowRegisterPopup(true)}
                      >
                        <FontAwesomeIcon
                          icon={faSignInAlt}
                          width={18}
                          height={18}
                          className="fs-18"
                        />{" "}
                        Sign Up
                      </button>
                    </div>
                    <div className="nav-item d-flex align-items-center">
                      <button
                        className="fs-23 text-white fw-500 ms-3 px-3 pb-1 me-0 login-sign-up btn-login rounded"
                        title="Login"
                        onClick={() => setShowLoggedInPopup(true)}
                      >
                        <FontAwesomeIcon
                          icon={faSignInAlt}
                          width={18}
                          height={18}
                          className="fs-18"
                        />{" "}
                        Login
                      </button>
                    </div>
                  </div>
                </li>
              )}
            </ul>
          </Navbar>
        </Navbar>
      </div>

      {showLocationbar && (
        <section className="header-location px-5 font-sfpro">
          <div className="p-4 container">
            <h4 className="label-color-2 mb-3 fw-600">Your Current Location</h4>
            <div className="position-relative">
              {/* <Form.Control
                ref={searchInput}
                name="searchLocation"
                className="location-header-input shadow-none pe-4"
                placeholder="Type your location"
                onChange={autoCompleteAddress}
                autoFocus={true}
              /> */}
              <Autocomplete
                apiKey="AIzaSyARph5RhpTDNEFctv0UgWunOrHU9oYuczE"
                ref={searchInput}
                name="searchLocation"
                className="location-header-input shadow-none pe-4"
                placeholder="ENTER YOUR LOCATION"
                options={{
                  types: ["geocode", "establishment"],
                }}
                onChange={autoCompleteAddress}
                autoFocus={true}
              />
              <div className="position-absolute top-0 end-0 mt-4 me-3 cursor-pointer">
                <img
                  src={locator}
                  alt="location1"
                  width={25}
                  className="locator"
                  onClick={findMyLocation}
                />
              </div>
            </div>
            <div className=" mt-4 d-flex align-items-center gap-5">
              <img
                alt="close1"
                src={cross1}
                width={25}
                className="cursor-pointer ms-auto"
                onClick={() => setShowLocationbar(false)}
              />
            </div>
          </div>
        </section>
      )}
      {showMenuBar && (
        <section className="header-menu-bar bg-white px-5 font-sfpro">
          <div className="p-5 container py-4">
            <div className="row text-uppercase fs-23 fw-500 label-color-2 font-sfpro">
              {serviceSegments?.map((item, index) => (
                <div
                  key={index}
                  className="col-lg-3 d-flex align-items-center pb-3"
                >
                  <Link href={`/service/${item.title}`}>
                    <a
                      className="text-decoration-none label-color-2 fs-18"
                      onClick={() => setShowMenuBar(false)}
                    >
                      <img
                        src={item.image}
                        className="me-3"
                        width={35}
                        height={35}
                        alt={item.name}
                      />
                      {item.name}
                    </a>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
